import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { imageToBackgroundImageCss } from 'utils/parse-image';
import { breakpointAndUp, colors, typography } from 'assets/styles';

export const ImageContainer = styled.div`
  ${({ $image }) => imageToBackgroundImageCss($image)};
  position: relative;
  padding-top: 100%;

  border-radius: 0.8rem;
  overflow: hidden;
  transform: translateZ(0); // safari fix for rounded borders
  transition: transform 200ms ease-in-out, filter 200ms ease-in-out;

  background-color: ${({ $isDark, $seeThroughBackgroundColor }) =>
    $seeThroughBackgroundColor ? rgba(colors.white, 0.2) : $isDark ? rgba(colors.white, 0.1) : colors.dividerLM};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ $hasHeight }) =>
    $hasHeight &&
    css`
      @media (${breakpointAndUp.tabletLarge}) {
        padding-top: 0;
        height: 16.4rem;
      }

      @media (${breakpointAndUp.desktop}) {
        padding-top: 0;
        height: 20.8rem;
      }
    `}

  ${({ $hasSetAspectRatio }) =>
    $hasSetAspectRatio &&
    css`
      padding-top: 0;
      aspect-ratio: 320 / 192;

      @media (${breakpointAndUp.tabletLarge}) {
        padding-top: 0;
        height: auto;
      }

      @media (${breakpointAndUp.desktop}) {
        padding-top: 0;
        height: auto;
      }
    `};
`;

export const Container = styled.article`
  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      ${ImageContainer} {
        transform: translateZ(0) translateY(-0.4rem);
      }
    }
  }
`;

const sharedMetaStyle = css`
  ${typography.caption};
  display: flex;
  align-items: center;
  padding: 0.6rem;
  border-radius: 0.4rem;
  color: ${colors.white};

  @supports ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) {
    backdrop-filter: blur(2rem);
  }
`;

export const PremiumContainer = styled.div`
  ${sharedMetaStyle};
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.6rem;
  line-height: 62.5%;
  border-radius: 50%;
  background-color: ${rgba(colors.black, 0.3)};

  @supports ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) {
    background-color: ${rgba(colors.black, 0.1)};
  }
`;

export const MetaContainer = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
`;

export const TypeIconContainer = styled.span`
  ${sharedMetaStyle};
  font-size: 1.6rem;
  line-height: 62.5%;
  background-color: ${rgba(colors.black, 0.3)};

  @supports ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) {
    background-color: ${rgba(colors.black, 0.1)};
    backdrop-filter: blur(2rem);
  }
`;

export const MetaText = styled.span`
  ${sharedMetaStyle};
  background-color: ${rgba(colors.black, 0.3)};

  @supports ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) {
    background-color: ${rgba(colors.black, 0.1)};
    backdrop-filter: blur(2rem);
  }
`;

export const MetaIconContainer = styled.span`
  margin-right: 0.4rem;
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const Content = styled.div`
  padding-top: 1rem;
`;

export const Label = styled.span`
  ${typography.captionSmall};
  color: ${colors.greyTwo};
`;

export const Title = styled.h1`
  ${typography.h5};
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
`;

export const Description = styled.p`
  ${typography.captionSmall};
  color: ${colors.greyTwo};
`;
