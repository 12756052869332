import React, { ComponentProps } from 'react';
import { ImageSet, DeviceImageSet } from 'models/image';
import { HeartFilled, Lock } from 'assets/icons';
import * as Styled from './content-card.style';

export type ContentCardProps = ComponentProps<'article'> & {
  image: ImageSet | DeviceImageSet;
  title: string;
  label?: string;
  description?: string;
  typeIcon?: JSX.Element;
  metaLabel?: string;
  metaIcon?: JSX.Element;
  imageHasHeight?: boolean;
  imageHasSetAspectRatio?: boolean;
  isDark?: boolean;
  seeThroughBackgroundColor?: boolean;
  isPremium?: boolean;
  isFavorite?: boolean;
};

const ContentCard: React.FC<ContentCardProps> = ({
  image,
  typeIcon,
  metaLabel,
  metaIcon,
  title,
  label,
  description,
  imageHasHeight,
  imageHasSetAspectRatio,
  isDark,
  seeThroughBackgroundColor,
  isPremium,
  isFavorite,
  ...rest
}) => (
  <Styled.Container {...rest}>
    <Styled.ImageContainer
      $isDark={isDark}
      $seeThroughBackgroundColor={seeThroughBackgroundColor}
      $image={image}
      $hasHeight={imageHasHeight}
      $hasSetAspectRatio={imageHasSetAspectRatio}
    >
      {isPremium && (
        <Styled.PremiumContainer>
          <Lock />
        </Styled.PremiumContainer>
      )}
      {!isPremium && isFavorite && (
        <Styled.PremiumContainer>
          <HeartFilled />
        </Styled.PremiumContainer>
      )}
      <Styled.MetaContainer>
        {typeIcon && <Styled.TypeIconContainer>{typeIcon}</Styled.TypeIconContainer>}
        {metaLabel && (
          <Styled.MetaText>
            {metaIcon && <Styled.MetaIconContainer>{metaIcon}</Styled.MetaIconContainer>}
            {metaLabel}
          </Styled.MetaText>
        )}
      </Styled.MetaContainer>
    </Styled.ImageContainer>
    <Styled.Content>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.Title $isDark={isDark}>{title}</Styled.Title>
      {description && <Styled.Description>{description}</Styled.Description>}
    </Styled.Content>
  </Styled.Container>
);

export default ContentCard;
